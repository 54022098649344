import { GetStaticPropsResult } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Link } from '@prickle/next-ui';
import { i18nConfig } from '../next-i18next.config';
import { Locale, NotFoundProps, Params } from '../types';
import getPrismicMenuByLocale from '../utils/prismic/menu';
import getPrismicOptionsByLocale from '../utils/prismic/options';
import { Layout } from '../components';
import { REVALIDATION_TIME } from '../utils/constants';
export default function Assignment404({
  options,
  menu,
  locale,
}: NotFoundProps) {
  const localeToLanguage = {
    'es-ES': 'español',
    'en-GB': 'English',
    'nl-NL': 'Nederlands',
  };
  const { t } = useTranslation();

  return (
    <Layout options={options} menu={menu}>
      <section className="grid w-full h-full p-6 text-lg text-white place-items-center bg-dark">
        <div className="container mx-auto">
          <div
            data-testid="notfound-text"
            className="my-16 text-5xl text-center text-white font-body"
          >
            {t('not-found', {
              language: localeToLanguage[locale],
              ns: 'errors',
            })}{' '}
            {t('or', {
              ns: 'common',
            })}{' '}
            {t('go-back', {
              ns: 'common',
            })}{' '}
            <Link color="primary" href="/" as="next-link" hover>
              {' '}
              {t('home-page', {
                ns: 'common',
              })}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const getStaticProps = async ({
  previewData,
  locale,
}: {
  params: Params;
  preview: boolean;
  previewData: any;
  locale: Locale;
  defaultLocale: string;
}): Promise<GetStaticPropsResult<NotFoundProps>> => {
  const menu = await getPrismicMenuByLocale(locale, previewData);
  const options = await getPrismicOptionsByLocale(locale, previewData);

  return {
    props: {
      ...(await serverSideTranslations(
        locale,
        ['common', 'errors'],
        i18nConfig,
      )),
      locale: locale as Locale,
      options,
      menu,
    },
    revalidate: REVALIDATION_TIME,
  };
};
